<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 03/04/2021
          </div>
          <div id='tip'>
            <pre>
            You've received your bonus delta tokens.
              I agreed with them to not be vested or locked at all. Pure tokens for you.
              However, don't dump them immediately. Two reasons: 1) let's show them some respect for doing the good deed, not punish it 2) a couple days ago Delta had a +50% day. Means it started moving. Now are good times. I wouldn't sell bonus nor the main thing before profit.
              Let's do like this:
              Target 1: 30% break-even (with bonus included)
              target 2: 3x (main)
              Target 3: 6x (small)
              </pre>
          </div>
        </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 03/04/2021
          </div>
          <div id='tip'>
            https://app.sushi.com/swap?outputCurrency=0xfcfc434ee5bff924222e084a8876eee74ea7cfba
          </div>
        </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:  01.04.2021 18:08:20 UTC
          </div>
          <div id='tip'>
            Delta also has increased risk after Uniswap personal fight with the team.
          </div>
      </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:  28.03.2021 17:19:13 UTC
          </div> 
          <div id='tip'>
            The official Delta token address is 0x9EA3b5b4EC044b70375236A281986106457b20EF
The official Delta uniswap pair address is 0x7d7e813082ef6c143277c71786e5be626ec77b20
          </div>
      </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 28.03.2021 13:54:43 UTC
          </div>
          <div id='tip'>
            I'll be on chat to answer some pre-staking questions, but basically, we wait for the countdown with the wallet that we used to contribute at delta.financial - then step 2) we make sure we have some eth for gas fees, and when countdown comes to 00:00 we claim tokens and stake them, we will see on the site how.
          </div>
      </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 28.03.2021 01:14:17 UTC
          </div>
          <div id='tip'>
            Delta Financial will launch tomorrow at 12 PM Eastern Time. Here you can see a countdown leading up to the event: 

              https://delta.financial/

              You have 1 hour to claim & stake your rLP tokens on the official Delta financial website, starting at 12 PM Eastern Time. 

              

              Please note that you can still claim your rLP tokens at a later stage. The 1-hour window is just the deadline to get into the very first staking period, which is expected to have crazy APY. The first hours of CORE have generated a very high APY too.

              But no rLP token is lost if you claim at a later stage. If you cannot make it on time you just miss out on the first staking period.

              

              I advise to claim & stake the rLP within this hour and keep them staked until further notice. I expect that it will take a few days until someone opens a trading pool for rLP on Uniswap or Balancer. If time has come we will observe how price develops and then decide which is the best time to sell. Until then we stake our rLP to generate DELTA and ETH rewards.
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DELTA',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
